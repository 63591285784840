<template>
    <div>
        <header class="py-3">
            <div class="container">
                <div class="row">
                    <div class="col order-3 order-md-1 d-flex align-items-center">
                        <ul class="list-inline mx-auto mx-md-0 mt-3 mt-md-0 social-list">
                            <li class="list-inline-item"><a href="https://www.facebook.com/FundacionRebecaLan" target="_blank"><img src="@/assets/img/icon-fb.svg" alt="Facebook" class="img-fluid"></a></li>
                            <li class="list-inline-item"><a href="https://www.instagram.com/fundacionrebecalan/" target="_blank"><img src="@/assets/img/icon-instagram.svg" alt="Instagram" class="img-fluid"></a></li>
                            <li class="list-inline-item"><a href="https://www.youtube.com/channel/UCudyW4Q-rfwRgZfmnh1Up5A" target="_blank"><img src="@/assets/img/icon-youtube.svg" alt="YouTube" class="img-fluid"></a></li>
                            <li class="list-inline-item"><a href="https://twitter.com/FundRebecaLan" target="_blank"><img src="@/assets/img/icon-twitter.svg" alt="Twitter" class="img-fluid"></a></li>
                        </ul>
                    </div>
                    <div class="col-12 col-md-4 order-2 order-md-2 text-center">
                        <a :href="`${publicPath}`"><img src="@/assets/img/logo-rebeca-lan.svg" alt="Fundación Rebeca Lan" height="130" class="logo img-fluid"></a>
                    </div>
                    <div class="col order-1 order-md-3 d-none d-sm-flex justify-content-end align-items-center">
                        <div class="text-center">
                            <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=4DA37QSD4LTV2" target="_blank" class="btn btn-secret mb-2">DONAR</a><br><span class="text-secret"><b>Donataria autorizada</b></span>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <div class="navbar-light d-flex justify-content-around d-sm-none pb-3">
            <div class="text-center">
                <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=4DA37QSD4LTV2" target="_blank" class="btn btn-secret">DONAR</a>
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
        </div>

        <nav class="navbar navbar-expand-md navbar-dark bg-tangerine">
            <div class="container">
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav mx-auto">
                        <li class="nav-item nav-home">
                            <a class="nav-link" :href="`${publicPath}`">Inicio</a>
                        </li>
                        <li class="nav-item dropdown nav-beneficiarios">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Beneficiarios
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a class="dropdown-item" :href="`${publicPath}beneficiarios`">Antecedentes</a>
                                <a class="dropdown-item" :href="`${publicPath}beneficiarios#testimonios`">Testimonios</a>
                            </div>
                        </li>
                        <li class="nav-item dropdown nav-nosotros">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Nosotros
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a class="dropdown-item" :href="`${publicPath}nosotros`">Nuestra historia</a>
                                <a class="dropdown-item" :href="`${publicPath}nosotros#mision`">Misión</a>
                                <a class="dropdown-item" :href="`${publicPath}nosotros#valores`">Valores</a>
                                <a class="dropdown-item" :href="`${publicPath}nosotros#premios`">Premios</a>
                            </div>
                        </li>
                        <li class="nav-item dropdown nav-trabajo">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Nuestro trabajo
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a class="dropdown-item" :href="`${publicPath}nuestro-trabajo`">Dignificación</a>
                            <a class="dropdown-item" :href="`${publicPath}nuestro-trabajo#sanacion`">Sanación</a>
                            <a class="dropdown-item" :href="`${publicPath}nuestro-trabajo#vinculo`">Vínculo familiar</a>
                            <a class="dropdown-item" :href="`${publicPath}nuestro-trabajo#inclusion`">Inclusión laboral</a>
                            </div>
                        </li>
                        <li class="nav-item dropdown nav-sumate">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Súmate
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a class="dropdown-item" :href="`${publicPath}sumate`">Donativos en Especie</a>
                            <a class="dropdown-item" :href="`${publicPath}sumate#apoyo`">Apoyo Voluntario</a>
                            </div>
                        </li>

                        <li class="nav-item nav-prensa">
                            <a class="nav-link" :href="`${publicPath}#prensa`">Prensa</a>
                        </li>
                        <li class="nav-item ml-lg-4">
                            <a class="nav-link " :href="`${publicPath}en/home`"><img src="@/assets/img/us.svg" class="rounded-pill mr-2" height="15" >English</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <a href="https://wa.me/6643475641" target="_blank" class="share shadow"><b>Compartir</b></a>
    </div>
</template>

<script>

export default {
    name: 'Header',
    data () {
      return {
          publicPath: process.env.BASE_URL
      }
    },
    mounted() {
    },
    methods: {
    }
}
</script>