<template>
<div>
    <Header />

     <div class="banner bg-dark text-white">
            <div class="container">
                <div class="row">
                    <div class="col py-5">
                        <h1><b>Áreas de atención</b></h1>
                    </div>
                </div>
            </div>
        </div>

        <div class="py-10 bg-gradient arrow-bottom">
            <div class="container">

                <div id="dignificacion" class="row row-cols-1 row-cols-md-2 mb-5">
                    <div class="col mb-4 mb-md-0 text-center">
                        <img src="@/assets/img/biblioteca1.jpg" alt="Bibliotecas Rebeca Lan" class="img-fluid shadow rounded">
                    </div>
                    <div class="col d-flex align-items-center">
                        <div>
                            <h2 class="h3"><b>Dignificación en centros penitenciarios</b></h2>
                            <p class="lead">Creamos bibliotecas para acercar a las mujeres a la lectura, como una herramienta más de transformación personal.<br>-Rashel Cohen Lan</p>
                            <p>Es importante que las mujeres cuenten con <b>espacios dignos</b> que beneficien su proceso de reinserción social. Buscamos que, en ese lugar donde pasarán largo tiempo,  se viva una experiencia de <b>crecimiento y aprendizaje</b>.</p>
                        </div>
                    </div>
                </div>
                <div class="row row-cols-1 row-cols-md-2 mb-5">
                    <div class="col">
                        <p class="text-secret"><b>Bibliotecas Rebeca Lan</b></p>
                        <p>Habilitamos las <b>Bibliotecas Rebeca Lan</b> dentro de los centros penitenciarios. Los libros no solo contienen <b>conocimientos</b>, los libros <b>abren puertas y ventanas en su imaginación</b> para viajar a nuevos escenarios y sentirse libres a través de la lectura. Cada uno de los libros donados, incluye en la primera página, una dedicatoria con un mensaje inspirador. Además, en la biblioteca se imparten diversos talleres.</p>
                    </div>
                    <div class="col">
                        <p class="text-secret"><b>Ludoteca y dormitorios para los niños y sus madres</b></p>
                        <p>En México existen más de 500 niños y niñas que viven en prisión bajo el cuidado de sus madres por un periodo de cinco años once meses. Estos <b>niños y niñas</b> requieren una mayor protección y cuidados para poder <b>desarrollarse sanamente</b> y aspirar a una vida digna.</p>
                        <p>En las <b>Ludotecas Rebeca Lan</b> se acondicionan espacios para que los niños y niñas pasen tiempo de calidad con sus madres. Así se fortalece el vínculo entre madre e hijos, a través de actividades lúdicas y acercándose a una vida similar a la de un niño en el exterior.</p>
                        <p>Los <b>Dormitorios Rebe Lan</b> son espacios específicos creados para fortalecer la seguridad y desarrollo mental de los hijos que viven con sus madres en el confinamiento. Están equipados como un lugar amigable y cómodo para su descanso.</p>
                    </div>
                </div>


                <div id="sanacion" class="row row-cols-1 row-cols-md-2 py-10">
                    <div class="col">
                        <h2 class="h3"><b>Sanación e inteligencia emocional</b></h2>
                        <p>Las mujeres, al ingresar a los centros penitenciarios, se sienten angustiadas y llenas de incertidumbre, esto les puede provocar depresión y desórdenes emocionales.  Esta condición podría ser un impedimento para <b>transformar lo negativo</b>, en una <b>oportunidad de crecimiento</b>.</p>
                        <p>La <b>Fundación Rebeca Lan</b> acerca a expertos de diferentes temas para que brinden herramientas que les permita continuar con su reinserción desarrollando habilidades socioemocionales a través de la impartición de distintos talleres de desarrollo humano, capacitaciones, conferencias, cursos, programas, etc., que pueden ser de manera presencial o virtual.</p>

                        <p class="text-secret mt-5"><b>Orquesta Rebeca Lan</b></p>
                        <p class="font-italic">La música es un viaje hacia el centro de uno mismo para volver a unir la mente,  el cuerpo y el espíritu.<br>- Rebeca Lan.</p>
                        <p>La <b>Orquesta Rebeca Lan</b> está conformada por 30 mujeres que toman clases teórico - prácticas para aprender a tocar algún instrumento musical. Una orquesta motiva el trabajo en equipo, genera disciplina y el resultado final, eleva su autoestima.</p>
                    </div>
                    <div class="col text-center">
                        <img src="@/assets/img/orquesta2.jpg" alt="Orquesta Rebeca Lan" class="img-fluid shadow rounded">
                    </div>
                </div>
            </div>
        </div>

        <div id="vinculacion" class="py-10 bg-gradient">
            <div class="container">
                <div id="vinculo" class="row row-cols-1 row-cols-md-2 mb-5">
                    <div class="col mb-4 mb-md-0">
                        <img src="@/assets/img/familia.jpg" alt="Familia" class="img-fluid shadow rounded">
                    </div>
                    <div class="col d-flex align-items-center">
                        <div>
                        <h2 class="h3"><b>Vínculo familiar y maternidad</b></h2>
                        <p class="lead">Cuando la familia está unida, se pueden superar las dificultades.<br>-Rashel Cohen Lan</p>
                        <p>El núcleo familiar de las mujeres reclusas en México se ve afectado porque se fractura la relación de las <b>madres con sus hijos</b> desde el momento de la separación. Mantener estos <b>lazos familiares</b> les favorece para superar momentos de crisis. Trabajamos para <b>reconstruir y fortalecer</b> estos lazos.</p>
                        </div>
                    </div>
                </div>
                <div class="row row-cols-1 row-cols-md-2 mb-5">
                    <div class="col mb-5">
                        <p class="text-secret"><b>Reencuento familiar</b></p>
                        <p>En las cárceles de México 9 de cada 10 mujeres no reciben visita. <b>Propiciamos acercamientos</b> entre las familias, principalmente entre los hijos e hijas con sus madres. No solamente quienes se encuentran en la misma ciudad, sino incluso con aquellos que viven en  otros estados del país. Se han dado casos en los que pueden pasar años, e inclusive toda la sentencia, sin que las mujeres privadas de su libertad, estén en contacto con sus hijos.</p>
                    </div>
                    <div class="col mb-5">
                        <p class="text-secret"><b>Maternal con Rebeca Lan</b></p>
                        <p>Los niños y niñas que viven en prisión con sus madres, están en un espacio que tiene los estigmas de una prisión;  la mayoría no cuenta con las necesidades básicas y no tienen las mismas oportunidades que los infantes que viven una vida normal. Para <b>contribuir al bienestar</b> de estos niños y niñas, el programa <b>Maternal con Rebeca Lan</b> organiza actividades lúdicas dentro del penal, así como salidas a lugares recreativos.</p>
                    </div>
                    <div class="col mb-5">
                        <p class="text-secret"><b>Uniendo familias a distancia</b></p>
                        <p>Buscamos fortalecer los vínculos familiares de las mujeres mediante videollamadas que les permita un reencuentro a distancia con sus seres queridos, principalmente con sus hijos e hijas, para que el día que se reinserten a la sociedad tengan una relación más fuerte, sana y llevadera.</p>
                    </div>
                    <div class="col">
                        <p class="text-secret"><b>Terapia antes y después de la separación</b></p>
                        <p>Una de las situaciones más difíciles que enfrentan las <b>mujeres en prisión y sus hijos</b>, es la separación cuando los pequeños cumplen 6 años y tienen que ser separados de sus madres. Ofrecemos <b>terapia</b> a la madre y a los menores antes y después de la separación, aunque no dejará de ser dolorosa, podrán asimilar de mejor manera este proceso.</p>
                    </div>
                </div>

                <div id="inclusion" class="inclusion py-10">
                    <div class="row row-cols-1 row-cols-md-2 mb-5">
                        <div class="col-lg-8">
                            <div>
                            <h2><b>Inclusión laboral</b></h2>
                            <p class="lead">Para la mujer nada es imposible, sólo le lleva tiempo conseguirlo.<br>-Rashel Cohen Lan</p>
                            <p>Uno de los obstáculos más grandes que enfrentan las mujeres al salir de prisión, es la <b>carta de antecedentes penales</b>: sólo el 5% de ellas logra conseguir un empleo. La Fundación Rebeca Lan se ocupa de generar beneficios para que las mujeres liberadas puedan ser productivas y <b>crecer laboralmente</b>. Cuando logran atender sus necesidades y las de sus hijos, se propician círculos de seguridad familiar</p>
                            </div>
                            <br><br>
                            <div>
                                <p class="text-secret"><b>CREO EN TI Mujer Lan</b></p>
                            <p>Realizamos talleres y capacitaciones dentro de <b>prisión</b> preparando a las <b>mujeres</b> para que puedan acceder a un empleo digno cuando sean puestas en libertad. Hacemos alianzas con la iniciativa pública, privada y la sociedad civil para que las reciban con una oportunidad de trabajo.</p>
                                </div>
                        </div>
                        <div class="col-lg-4 text-center">
                        <img src="@/assets/img/maria-jasjane.jpg" alt="Maria Jasjane" class="img-fluid shadow rounded">
                        </div>
                    </div>
                    <!-- <div class="row row-cols-1 row-cols-md-2">
                        <div class="col">
                            <p class="text-secret"><b>CREO EN TI Mujer Lan</b></p>
                            <p>Realizamos talleres y capacitaciones dentro de <b>prisión</b> preparando a las <b>mujeres</b> para que puedan acceder a un empleo digno cuando sean puestas en libertad. Hacemos alianzas con la iniciativa pública, privada y la sociedad civil para que las reciban con una oportunidad de trabajo.</p>
                        </div>
                        <div class="col">
                            <p class="text-secret"><b>Emprendedora Lan</b></p>
                            <p class="font-italic">Para la mujer nada es imposible, sólo le lleva tiempo conseguirlo.<br>- Rashel cohen</p>
                            <p>Impulsamos el emprendimiento en las <b>mujeres presas</b>, ofreciendo acompañamiento, brindándoles las bases necesarias para la creación de su <b>micro negocio</b>. Así, podrán ser autosustentables y  reconstruir su vida junto a sus hijos.</p>
                        </div> 
                    </div>-->
                </div>
            </div>
        </div>
    <Footer />
</div>
</template>

<style lang="less">
    @import "@/assets/css/colors.less";
    @import "@/assets/css/main.less";
    @import "./css/nosotros.less";
    .bg-dark{
        overflow: hidden;
        position: relative;
        background: rgb(0,0,0);
        background: linear-gradient(90deg, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%);
        text-shadow: 0 1px 15px rgba(0,0,0,.8);

        &::before {
            background-image: url('../../assets/img/bg-image.jpg');
            content: "";
            background-size: cover;
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            opacity: 0.35;
        }
    }
</style>

<script>
import Header from '@/modules/Templates/Header'
import Footer from '@/modules/Templates/Footer'

export default {
    name: "NuestroTrabajo",
    components:{
        Header,
        Footer
    },
    mounted(){
       if(window.location.hash) {
            const id = window.location.hash.substring(1);
            const yourElement = document.getElementById(id);
            const y = yourElement.getBoundingClientRect().top + window.pageYOffset;

            window.scrollTo({top: y, behavior: 'smooth'});
        }
    }
}
</script>

